// AUTH
export const LOGIN = "/login";

// MAIN
export const ADJUSTMENT_FORM = "/adjustments/form/:invoiceId";
export const ADMIN_TASKS = "/tasks";
export const CARNET_ORDERS = "/carnet-orders";
export const CARNET_ORDER = `${CARNET_ORDERS}/:carnetOrderId`;
export const CARNET_TEMPLATES = "/carnet-templates";
export const CARNETS = "/carnets";
export const CASHIERS = "/cashiers";
export const DASHBOARD = "/dashboard";
export const EVENT_TEMPLATES = "/event-templates";
export const EVENTS = "/events";
export const EVENT = `${EVENTS}/:eventId`;
export const EXTERNAL_INVOICES = "/external-invoices";
export const FAQ_CONTACTS = "/client-tasks";
export const INVOICE_FORM = "/invoices/form";
export const INVOICE_FORM_ROUTE = "/invoices/form/:invoiceId?";
export const INVOICES = "/invoices";
export const JOINED_SECTOR_BUNDLES = "/joined-tickets";
export const JOINED_SECTOR_BUNDLE = `${JOINED_SECTOR_BUNDLES}/:joinedSectorBundleId`;
export const OLD_INVOICES = "/legacy-invoices";
export const ORDERS = "/orders";
export const ORDER = `${ORDERS}/:orderId`;
export const PREPAID_CARNET_TEMPLATES = "/eko-cards";
export const PRODUCT_ORDERS = "/product-orders";
export const PRODUCT_ORDER = `${PRODUCT_ORDERS}/:productOrderId`;
export const PRODUCTS = "/products";
export const REPORTS = "/reports";
export const SETTINGS = "/settings";
export const STANDS = "/stands";
export const TICKETS = "/tickets";

// SECOND DISPLAY
export const SECOND_DISPLAY = "/second-display";

// FALLBACK
export const NOT_FOUND = "/not-found";
