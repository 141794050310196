import { Tile, TileImage } from "./";

import { IObjectTile } from "../../interfaces/sharedComponentInterfaces";

const ObjectTile = (props: IObjectTile) => {
  const { title, titleBg, paragraph, secondParagraph, pictureUrl, children, px, onClick, onPictureClick, onTitleClick, size, tooltipId, tooltipText } = props;
  const padding = px !== undefined ? px : 2 * 0.25;

  let height = "h-12";
  if (size === "small") height = "h-8";

  return (
    <Tile onClick={onClick} size={size} tooltipId={tooltipId} tooltipText={tooltipText}>
      <button className={`w-full flex justify-center px-2 py-1 ${titleBg}`} onClick={onTitleClick}>
        <p className="font-bold truncate text-elipsis">{title}</p>
      </button>

      {paragraph && <p className="truncate text-ellipsis pb-1">{paragraph}</p>}
      {secondParagraph && <p className="truncate text-ellipsis pb-1">{secondParagraph}</p>}

      <TileImage pictureUrl={pictureUrl} onPictureClick={onPictureClick} />
      <div className={`flex justify-center items-center ${height} w-full font-bold`} style={{ paddingLeft: padding, paddingRight: padding }}>
        {children}
      </div>
    </Tile>
  );
};

export default ObjectTile;
