import { useLocalStorage } from "./";

export const baseCashierSettings: IcashierSetting = {
  adminTasks: { perPage: 10, view: "tiles" },
  carnetOrders: { perPage: 10, view: "tiles" },
  carnets: { perPage: 10, view: "tiles" },
  carnetTemplates: { perPage: 10, view: "tiles" },
  cashiers: { perPage: 10, view: "tiles" },
  dashboard: { adminTasksPerPage: 5, faqContactsPerPage: 5, changelogsPerPage: 5 },
  events: { perPage: 10, view: "tiles" },
  eventTemplates: { perPage: 10, view: "tiles" },
  faqContacts: { perPage: 10, view: "tiles" },
  invoices: { perPage: 10 },
  joinedSectorBundles: { perPage: 10, view: "tiles" },
  oldInvoices: { perPage: 10 },
  orders: { perPage: 10, view: "tiles" },
  prepaidCarnetTemplates: { perPage: 10, view: "tiles" },
  productOrders: { perPage: 10, view: "tiles" },
  products: { perPage: 10, view: "tiles" },
  productsModal: { perPage: 10, view: "tiles" },
  stands: { perPage: 10, view: "tiles" },
  tickets: { perPage: 10, view: "tiles" }
};

export type IsettingsKeys =
  | "adminTasks"
  | "carnetOrders"
  | "carnets"
  | "carnetTemplates"
  | "cashiers"
  | "dashboard"
  | "events"
  | "eventTemplates"
  | "faqContacts"
  | "invoices"
  | "joinedSectorBundles"
  | "oldInvoices"
  | "orders"
  | "prepaidCarnetTemplates"
  | "productOrders"
  | "products"
  | "productsModal"
  | "stands"
  | "tickets";

const useCashierSettings = () => {
  const [currentCashier] = useLocalStorage("current_cashier", null);
  const [settings, setSettings] = useLocalStorage("cashiers_settings", { [currentCashier ? currentCashier.id : -1]: baseCashierSettings });

  const setSettingsWithActionLogger = (newSettings: IcashierSetting) => {
    setSettings(newSettings);
    // createActionLogger({ action_type: "cashier_settings", info: newSettings }).then((response) => { handleCreateActionLogger({ response }) });
  };

  const currentCashierSetting = () => {
    if (!currentCashier) return baseCashierSettings;

    const currentSettings = settings[currentCashier.id];
    return currentSettings ? { ...baseCashierSettings, ...currentSettings } : baseCashierSettings;
  };

  const getCurrentViewSettings = (view: IsettingsKeys) => {
    if (!currentCashier) return baseCashierSettings;

    const currentSettings = currentCashierSetting();
    return currentSettings[view];
  };

  const setCurrentCashierSettings = (newSettings: IcashierSetting, id?: number) => {
    if (!currentCashier) return baseCashierSettings;

    const currentCashierId = id ? id : currentCashier.id;

    const deepCopy = JSON.parse(JSON.stringify(settings));
    deepCopy[currentCashierId] = newSettings;
    setSettingsWithActionLogger(deepCopy);
  };

  const setUpSettings = (id: number) => {
    if (!currentCashier) return baseCashierSettings;

    if (!settings[id]) {
      setSettingsWithActionLogger({ ...settings, [id]: baseCashierSettings });
      return;
    } else {
      const deepCopy = JSON.parse(JSON.stringify(baseCashierSettings));
      Object.keys(deepCopy).forEach((keyCopy) => {
        const baseSetting = deepCopy[keyCopy as keyof IcashierSetting];
        const currentSetting = settings[id][keyCopy as keyof IcashierSetting];
        if (!currentSetting) {
          deepCopy[keyCopy] = baseSetting;
          return;
        }
        Object.entries(baseSetting).forEach((entry) => {
          const [key, value] = entry;
          if (!currentSetting[key]) {
            deepCopy[keyCopy][key] = value;
          } else {
            deepCopy[keyCopy][key] = currentSetting[key];
          }
        });
      });
      setCurrentCashierSettings(deepCopy, id);
    }
  };

  const changeCurrentCashierSetting = (key: IsettingsKeys, value: IviewSettings) => {
    const setting = currentCashierSetting();
    setting[key] = value;
    setCurrentCashierSettings(setting);
  };

  return { setUpSettings, getCurrentViewSettings, changeCurrentCashierSetting };
};

export default useCashierSettings;

export interface IcashierSetting {
  adminTasks: IviewSettings;
  carnetOrders: IviewSettings;
  carnets: IviewSettings;
  carnetTemplates: IviewSettings;
  cashiers: IviewSettings;
  dashboard: { adminTasksPerPage: number; faqContactsPerPage: number; changelogsPerPage: number };
  events: IviewSettings;
  eventTemplates: IviewSettings;
  faqContacts: IviewSettings;
  invoices: IviewSettings;
  joinedSectorBundles: IviewSettings;
  oldInvoices: IviewSettings;
  orders: IviewSettings;
  prepaidCarnetTemplates: IviewSettings;
  productOrders: IviewSettings;
  products: IviewSettings;
  productsModal: IviewSettings;
  stands: IviewSettings;
  tickets: IviewSettings;
}

export interface IviewSettings {
  perPage: number;
  view?: "rows" | "tiles";
}
