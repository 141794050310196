import { useState, useLayoutEffect, Suspense, lazy } from "react";

import { SnackbarProvider } from "notistack";

import BasicRoutes from "./containers/Routes/BasicRoutes";
import AuthenticatedRoutes from "./containers/Routes/AuthenticatedRoutes";
import Auth from "./containers/Auth/Auth";
import { Loader } from "./shared/components";

import { useBaseStore } from "./store";
import { useFiscalPrinterSettings, useLocalStorage, useTerminalSettings } from "./hooks";

import i18n from "./i18n";
import authHeader from "./services/auth-header";
import { autoRefreshCashierToken } from "./services/auth.service";
import { handleTerminalPostMessage } from "./services/terminal.service";
import { IfiscalPrinterSettings } from "./interfaces/printerInterfaces";
import { SECOND_DISPLAY } from "./shared/routes";
import { IOldTerminalSettings, IterminalSettings } from "./interfaces/terminalInterfaces";
import { Tooltip } from "react-tooltip";

const Layout = lazy(() => import("./containers/Layout/Layout"));
const SecondDisplayLayout = lazy(() => import("./containers/SecondDisplay/SecondDisplayLayout"));

const App = () => {
  const [locale] = useLocalStorage("cashier_locale", "pl");
  const fiscalPrinterSettings: IfiscalPrinterSettings = useFiscalPrinterSettings()[0];
  const [terminalSettings, , setNativeTerminalSettings] = useTerminalSettings();
  const [loggedIn, setLoggedIn] = useState<boolean>(authHeader() !== "");
  const setTerminalQuestionData = useBaseStore((state) => state.setTerminalQuestionData);

  useLayoutEffect(() => {
    onTryAutoSignup();
    i18n.changeLanguage(locale);

    if (isOldTerminalSettings(terminalSettings)) {
      //TODO: CHECK IF THIS IS CORRECT. WHY THERE
      setNativeTerminalSettings((terminalSettings: any) => {
        return null;
      });
    }

    if (loggedIn) window.addEventListener("message", (event) => handleTerminalPostMessage(event, fiscalPrinterSettings, setTerminalQuestionData, terminalSettings));

    return () => {
      window.removeEventListener("message", (event) => handleTerminalPostMessage(event, fiscalPrinterSettings, setTerminalQuestionData, terminalSettings));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTryAutoSignup = () => {
    const token = authHeader();
    if (token) {
      autoRefreshCashierToken()
        .then((res) => {
          if (res.status === 200) setLoggedIn(true);
        })
        .catch(() => setLoggedIn(false));
    }
  };

  const isOldTerminalSettings = (obj: any): obj is IOldTerminalSettings =>
    typeof obj === "object" && obj !== null && "terminalPayment" in obj && "port" in obj && typeof obj.port === "object" && "name" in obj.port && !("connection_type" in obj); // Ensures it's not already in the new format

  const convertTerminalSettings = (oldSettings: IOldTerminalSettings): IterminalSettings => ({
    terminalPayment: oldSettings.terminalPayment,
    connection_type: "serial", // Default to 'serial' since old format only had serial
    port_name: oldSettings.port?.name, // Map old port.name to new port_name
    protocol: oldSettings.protocol ?? "it_card" // Default to 'it_card' if not provided
  });

  let routes = <BasicRoutes />;

  if (loggedIn) {
    routes = <AuthenticatedRoutes />;
  }

  let layout = <Auth>{routes}</Auth>;

  if (loggedIn) {
    layout = (
      <Suspense
        fallback={
          <div className="w-full h-full flex justify-center items-center">
            <Loader />
          </div>
        }>
        {window.location.href.includes(SECOND_DISPLAY) ? <SecondDisplayLayout>{routes}</SecondDisplayLayout> : <Layout>{routes}</Layout>}
      </Suspense>
    );
  }

  return (
    <SnackbarProvider maxSnack={4} anchorOrigin={{ vertical: "top", horizontal: "center" }} dense>
      <Tooltip id="voucher-tooltip" className="z-[500]" />
      <main className="flex h-screen bg-gradient-to-t from-zinc-100 to-blue-100">{layout}</main>
    </SnackbarProvider>
  );
};

export default App;
