import isEqual from "react-fast-compare";

import { useLocalStorage } from ".";

import { INITIAL_TERMINAL_SETTINGS } from "../constants";
import { createActionLogger } from "../services/objects/cashiers.service";
import { handleCreateActionLogger } from "../services/responseDataHandler";

import { IterminalSettings } from "../interfaces/terminalInterfaces";

const useTerminalSettings = () => {
  const [value, setValue] = useLocalStorage("terminal_settings", INITIAL_TERMINAL_SETTINGS);

  const setValueWithActionLogger = (newValue: IterminalSettings) => {
    setValue((prev: IterminalSettings) => {
      const newValues = { ...prev, ...newValue };
      try {
        if (!isEqual(newValue, INITIAL_TERMINAL_SETTINGS)) {
          createActionLogger({ action_type: "pos_terminal_settings", info: newValue }).then((response) => {
            handleCreateActionLogger({ response });
          });
        }
      } catch (error) {
        console.error(error);
      }
      return newValues;
    });
  };

  return [value, setValueWithActionLogger, setValue];
};

export default useTerminalSettings;
