import { useCurrentOrdersStore } from "../../store";

import { IOrderObject, IOrderObjectHallSeatGroup, IOrderObjectHallSeats } from "../../interfaces/orderInterfaces";
import { IOrder, IOrderStore, ISeat, ISector, IselectedSeat } from "../../interfaces/storeInterfaces";
import { IReservationObject } from "../../interfaces/reservationInterfaces";

export const addReservation = (state: IOrderStore, reservation: IOrderObject) => {
  const orderId = Date.now();
  useCurrentOrdersStore.setState((state) => {
    state.setCurrentOrderId(orderId);
    return { ...state, currentOrderId: orderId };
  });
  const updatedOrders = JSON.parse(JSON.stringify(state.orders));
  const reservations = reservation.reservations;
  if (!reservations || reservations.length === 0) return { orders: updatedOrders };

  const hallId = reservation.hall_id;
  const hallWrapperElementId = reservation.hall_wrapper_element_id;
  const order: IOrder = {
    id: orderId,
    eventId: reservation.event_id,
    eventTitle: reservation.event_title,
    hallId: hallId,
    hallWrapperElementId: hallWrapperElementId,
    activities: activitiesFromReservations(reservations),
    // products: [],
    // TODO
    products: [],
    reservationId: reservation.id,
    reservationSeats: hallId ? seatsFromReservations(reservations) : [],
    sectors: hallId ? [] : sectorsFromReservations(reservations),
    seats: hallId ? seatsFromReservations(reservations) : [],
    selectedSeats: hallId ? selectedSeatsFromReservations(reservations, reservation.hall_seats, reservation.hall_seat_groups) : [],
    voucher: reservation.voucher
  };
  // updatedOrders.push(order);
  // return { orders: updatedOrders };
  return { orders: [order] };
};

// TODO
const activitiesFromReservations = (reservations: IReservationObject[]) => {
  // return reservations.map((reservation: IReservationObject) => {
  //   return reservation.activities;
  // });
  return [];
};

const sectorsFromReservations = (reservations: IReservationObject[]): ISector[] => {
  return Object.values(
    reservations.reduce((acc: any, { sector_id, ticket_typ, price, max_quantity }) => {
      if (!acc[sector_id]) {
        acc[sector_id] = { id: sector_id, name: ticket_typ, price: price, quantity: 0, maxQuantity: max_quantity || 50 };
      }
      acc[sector_id].quantity += 1;
      return acc;
    }, {})
  );
};

const seatsFromReservations = (reservations: IReservationObject[]): ISeat[] => {
  return reservations.map((reservation: IReservationObject) => {
    return {
      name: reservation.ticket_typ,
      price: +reservation.total_price,
      sectorId: reservation.sector_id,
      viewId: +(reservation.seat || 0)
    };
  });
};

const selectedSeatsFromReservations = (reservations: IReservationObject[], hallSeats?: IOrderObjectHallSeats, hallSeatGroups?: IOrderObjectHallSeatGroup[]): IselectedSeat[] => {
  if (!hallSeats || !hallSeatGroups) return [];

  return reservations.map((reservation: IReservationObject) => {
    let group = hallSeatGroups.find((group) => group.id === hallSeats[reservation.seat || 0].seat_group_id);

    return {
      description: group?.additional || reservation.spot_desc || "",
      fill: group?.color || "#73c46a",
      groupName: group?.name || "Normalne",
      sectorId: reservation.sector_id,
      viewId: +(reservation.seat || 0)
    };
  });
};
