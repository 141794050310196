import isEqual from "react-fast-compare";

import { useLocalStorage } from "./";

import { INITIAL_TICKET_PRINTER_SETTINGS } from "../constants";
import { createActionLogger } from "../services/objects/cashiers.service";
import { handleCreateActionLogger } from "../services/responseDataHandler";

import { IticketPrinterSettings } from "../interfaces/printerInterfaces";

const useTicketPrinterSettings = () => {
  const [value, setValue] = useLocalStorage("ticket_printer_settings", INITIAL_TICKET_PRINTER_SETTINGS);

  const setValueWithActionLogger = (newValue: IticketPrinterSettings) => {
    setValue((prev: IticketPrinterSettings) => {
      const newValues = { ...prev, ...newValue };
      try {
        if (!isEqual(newValue, INITIAL_TICKET_PRINTER_SETTINGS)) {
          createActionLogger({ action_type: "pos_ticket_printer_settings", info: newValue }).then((response) => {
            handleCreateActionLogger({ response });
          });
        }
      } catch (error) {
        console.error(error);
      }
      return newValues;
    });
  };

  return [value, setValueWithActionLogger];
};

export default useTicketPrinterSettings;
