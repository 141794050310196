import isEqual from "react-fast-compare";

import { useLocalStorage } from "./";

import { INITIAL_FISCAL_PRINTER_SETTINGS } from "../constants";
import { createActionLogger } from "../services/objects/cashiers.service";
import { handleCreateActionLogger } from "../services/responseDataHandler";

import { IfiscalPrinterSettings } from "../interfaces/printerInterfaces";

const useFiscalPrinterSettings = () => {
  const [value, setValue] = useLocalStorage("fiscal_printer_settings", INITIAL_FISCAL_PRINTER_SETTINGS);

  const setValueWithActionLogger = (newValue: IfiscalPrinterSettings) => {
    setValue((prev: IfiscalPrinterSettings) => {
      const newValues = { ...prev, ...newValue };
      try {
        if (!isEqual(newValue, INITIAL_FISCAL_PRINTER_SETTINGS)) {
          createActionLogger({ action_type: "pos_fiscal_printer_settings", info: newValue }).then((response) => {
            handleCreateActionLogger({ response });
          });
        }
      } catch (error) {
        console.error(error);
      }
      return newValues;
    });
  };

  const changePort = (value: string) => {
    setValue((prev: IfiscalPrinterSettings) => {
      const newValues = { ...prev, port: { ...prev.port, name: value } };
      try {
        if (!isEqual(newValues, INITIAL_FISCAL_PRINTER_SETTINGS)) {
          createActionLogger({ action_type: "pos_fiscal_printer_settings", info: newValues }).then((response) => {
            handleCreateActionLogger({ response });
          });
        }
      } catch (error) {
        console.error(error);
      }

      return newValues;
    });
  };

  return [value, setValueWithActionLogger, changePort];
};

export default useFiscalPrinterSettings;
