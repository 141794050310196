import i18n from "i18next";

import {
  IhandleCreateActionLogger,
  IhandleCreateInvoiceResponse,
  IhandleCreateOrderResponse,
  IhandleCreateResponse,
  IhandleDeleteResponse,
  IhandleEnqueueReportResponse,
  IhandleFetchObjectResponse,
  IhandleFetchObjectsResponse,
  IhandlePatchResponse,
  IhandleUpdateResponse
} from "../interfaces/handlerInterfaces";

export const handleCreateActionLogger = (params: IhandleCreateActionLogger) => {
  const { response } = params;

  if (response && response.status === 200) {
    console.log("Settings saved with action logger", response);
  } else {
    console.error("Error saving settings with action logger", response);
  }
};

export const handleCreateOrderResponse = (params: IhandleCreateOrderResponse) => {
  const { response, enqueueSnackbar, setLoading, setGlobalLoader, handleCorrectOrderCreated } = params;

  if (response && response.status === 200) {
    enqueueSnackbar(i18n.t("base.create_success", { object: i18n.t("objects.order") }), snackbarParams("success"));
    // if (params.handleClose) {
    //   params.handleClose();
    // }
    // Remove order from the list of orders
    // Set order_id in base store
    handleCorrectOrderCreated(response.data.message.order_id || -1);
    setLoading(false);
    // setGlobalLoader(false);
  } else {
    handleOrderResponseErrors(response, enqueueSnackbar);
    setLoading(false);
    setGlobalLoader(false);
  }
};

export const handleCreateInvoiceResponse = (params: IhandleCreateInvoiceResponse) => {
  const { response, enqueueSnackbar, setError, setLoading, handleCorrectInvoiceCreated } = params;
  const objectName = i18n.t("objects.pos_new_invoice");

  if (response && response.status === 200) {
    enqueueSnackbar(i18n.t("base.create_success", { object: objectName }), snackbarParams("success"));
    handleCorrectInvoiceCreated();
    setLoading(false);
  } else {
    handleResponseErrors(response, setError, enqueueSnackbar, objectName, "create");
    setLoading(false);
  }
};

export const handleCreateResponse = (params: IhandleCreateResponse) => {
  const { response, objectName, handleClose, setLoading, successCallback, setRefetchObjects } = params;

  if (response && response.status === 200) {
    if (objectName) params.enqueueSnackbar(i18n.t("base.create_success", { object: objectName }), snackbarParams("success"));
    else params.enqueueSnackbar(params.successMessage, snackbarParams("success"));
    if (successCallback) successCallback();
    if (handleClose) handleClose();
    setLoading(false);
    if (setRefetchObjects) setRefetchObjects(!params.refetchObjects);
  } else {
    handleResponseErrors(response, params.setError, params.enqueueSnackbar, objectName, "create", params.failureMessage);
    setLoading(false);
  }
};

export const handleDeleteResponse = (params: IhandleDeleteResponse) => {
  const { response, enqueueSnackbar, objectName, successCallback, successMessage, failureMessage, setLoading } = params;

  if (response && response.status === 200) {
    if (objectName) enqueueSnackbar(i18n.t("base.destroy_success", { object: objectName }), snackbarParams("success"));
    else enqueueSnackbar(successMessage, snackbarParams("success"));
    if (successCallback) successCallback();
  } else {
    if (objectName) enqueueSnackbar(i18n.t("base.destroy_fail", { object: objectName }), snackbarParams("error"));
    else enqueueSnackbar(failureMessage, snackbarParams("error"));
  }
  if (setLoading) setLoading(false);
};

export const handleEnqueueReportResponse = (params: IhandleEnqueueReportResponse) => {
  const { response, docType, enqueueSnackbar, setLoading, setReportJobToken } = params;

  if (response && response.status === 200) {
    enqueueSnackbar(i18n.t("reports.enqueue_success"), snackbarParams("success"));
    setReportJobToken(response.data.message.job_token, docType);
    setLoading(false);
  } else {
    enqueueSnackbar(i18n.t("reports.enqueue_fail"), snackbarParams("error"));
    setLoading(false);
  }
};

export const handleFetchObjectResponse = (params: IhandleFetchObjectResponse) => {
  const { response, setGlobalLoader, setObject } = params;

  if (response && response.status === 200) {
    setObject(response.data);
    if (setGlobalLoader) setGlobalLoader(false);
  } else {
    if (setGlobalLoader) setGlobalLoader(false);
    console.log(response);
  }
};

export const handleFetchObjectsResponse = (params: IhandleFetchObjectsResponse) => {
  const { response, setObjects, setTotalCount } = params;

  if (response && response.status === 200) {
    if (setTotalCount) setTotalCount(response.data.items_count);
    setObjects(response.data.items);
  } else {
    console.log(response);
  }
};

export const handlePatchResponse = (params: IhandlePatchResponse) => {
  const { response, enqueueSnackbar, successMessage, objectName, failureMessage, setError, setGlobalLoader, setLoading, successCallback } = params;

  if (response && response.status === 200) {
    if (objectName) enqueueSnackbar(i18n.t("base.update_success", { object: objectName }), snackbarParams("success"));
    else enqueueSnackbar(successMessage, snackbarParams("success"));

    setLoading(false);
    if (successCallback) successCallback();
    if (setGlobalLoader) setGlobalLoader(false);
  } else {
    handleResponseErrors(response, setError, enqueueSnackbar, objectName, "update", failureMessage);
    setLoading(false);
    if (setGlobalLoader) setGlobalLoader(false);
  }
};

export const handleResponseErrors = (response: any, setError: any, enqueueSnackbar: any, objectName?: string, action: string = "create", failure?: string) => {
  if (response && response.data.errors) {
    response.data.errors.forEach((error: any) => {
      let errorMessage = i18n.t("errors.invalid");
      if (error.details.error) {
        errorMessage = error.details.error;
      }
      if (error.field === "nip") errorMessage = i18n.t("errors.invalid_vat");
      if (error.field === "postal_code") errorMessage = i18n.t("errors.invalid_postal_code");
      if (errorMessage === "taken") errorMessage = i18n.t("errors.taken");
      setError(error.field, { message: errorMessage });
    });
  }
  if (objectName) enqueueSnackbar(i18n.t(`base.${action}_fail`, { object: objectName }), snackbarParams("error"));
  else enqueueSnackbar(failure, snackbarParams("error"));
};

export const handleUpdateResponse = (params: IhandleUpdateResponse) => {
  const { enqueueSnackbar, handleClose, objectName, response, setError, setLoading } = params;

  if (response && response.status === 200) {
    enqueueSnackbar(i18n.t("base.update_success", { object: objectName }), snackbarParams("success"));
    if (handleClose) handleClose();
    setLoading(false);
  } else {
    handleResponseErrors(response, setError, enqueueSnackbar, objectName, "update");
    setLoading(false);
  }
};

const handleOrderResponseErrors = (response: any, enqueueSnackbar: any) => {
  enqueueSnackbar(i18n.t("base.create_fail", { object: i18n.t("objects.order") }), snackbarParams("error"));
  if (response && response.data.errors) {
    response.data.errors.forEach((error: any) => {
      let errorMessage = i18n.t("errors.invalid");
      if (error.details.error) {
        errorMessage = error.details.error;
      }
      enqueueSnackbar(errorMessage, snackbarParams("error"));
    });
  }
};

const snackbarParams = (variant: "default" | "success" | "error" | "warning" | "info") => {
  return { variant: variant, autoHideDuration: 2000, preventDuplicate: true };
};
