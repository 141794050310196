import { IfiscalNonFiscalPrintCustomLine } from "../interfaces/printerInterfaces";
import { limitStringToPaperWidth, stripPolishCharacters } from "./commons";
import { IterminalPrintData } from "../interfaces/terminalInterfaces";

// TODO ???
export const handleFiscalNonFiscalTerminalPrintDataForOfflineReport = (terminalPrintData: IterminalPrintData, polishCharacters: boolean, paperWidth: 55 | 80) => {
  return handleFiscalNonFiscalTerminalPrintDataForSettlementReport(terminalPrintData, polishCharacters, paperWidth);
};

export const handleFiscalNonFiscalTerminalPrintDataForSettlementReport = (terminalPrintData: IterminalPrintData, polishCharacters: boolean, paperWidth: 55 | 80) => {
  let customLines: any[] = [
    { type: "empty_line" },
    { type: "text_line", text: stripPolishCharacters(terminalPrintData.Header || "", polishCharacters), fontSize: 0, centerText: 1 },
    { type: "text_line", text: stripPolishCharacters(terminalPrintData.TrDate || "", polishCharacters), fontSize: 0, centerText: 1 },
    { type: "dot_line" },
    { type: "text_line", text: stripPolishCharacters(terminalPrintData.Name, polishCharacters), fontSize: 0, centerText: 0 },
    { type: "text_line", text: stripPolishCharacters(terminalPrintData.Addr1, polishCharacters), fontSize: 0, centerText: 0 },
    { type: "text_line", text: stripPolishCharacters(terminalPrintData.Addr2, polishCharacters), fontSize: 0, centerText: 0 },
    { type: "empty_line" },
    { type: "dot_line" },
    { type: "empty_line" },
    handleFiscalNonFiscalTerminalPrintDataTwoInLine(["Nr raportu:", terminalPrintData.SetlBatch || ""], paperWidth),
    handleFiscalNonFiscalTerminalPrintDataTwoInLine([`TID: ${terminalPrintData.TID}`, `MID: ${terminalPrintData.MID}`], paperWidth),
    { type: "empty_line" },
  ];
  
  // BLIK
  if (terminalPrintData.SetlBlikName) {
    customLines.push(
      { type: "dot_line" },
      { type: "text_line", text: stripPolishCharacters(`${terminalPrintData.SetlBlikName}:`, polishCharacters), fontSize: 0, centerText: 0 },
      ...handleFiscalNonFiscalTerminalPrintDataSetlLines(terminalPrintData.SetlBlikList || [], polishCharacters, paperWidth),
      { type: "empty_line" }
    );
  }
  
  // DCC
  if (terminalPrintData.SetlDccName) {
    customLines.push(
      { type: "dot_line" },
      { type: "text_line", text: stripPolishCharacters(`${terminalPrintData.SetlDccName}:`, polishCharacters), fontSize: 0, centerText: 0 },
      ...handleFiscalNonFiscalTerminalPrintDataSetlLines(terminalPrintData.SetlDccList || [], polishCharacters, paperWidth),
      { type: "empty_line" }
    );
  }
  
  // SUMM
  if (terminalPrintData.SetlSummName) {
    customLines.push(
      { type: "dot_line" },
      { type: "text_line", text: stripPolishCharacters(`${terminalPrintData.SetlSummName}:`, polishCharacters), fontSize: 0, centerText: 0 },
      ...handleFiscalNonFiscalTerminalPrintDataSetlLines(terminalPrintData.SetlSummList || [], polishCharacters, paperWidth),
      { type: "empty_line" }
    );
  }
  
  // TRX
  if (terminalPrintData.SetlTrxName && terminalPrintData.SetlTrxList && terminalPrintData.SetlTrxList.length > 0) {
    customLines = [
      ...customLines,
      { type: "dot_line" },
      { type: "text_line", text: stripPolishCharacters(`${terminalPrintData.SetlTrxName}:`, polishCharacters), fontSize: 0, centerText: 0 },
      ...handleFiscalNonFiscalTerminalPrintDataSetlLines(terminalPrintData.SetlTrxList, polishCharacters, paperWidth),
      { type: "empty_line" }
    ];
  }

  return [...customLines, { type: "dot_line" }] as IfiscalNonFiscalPrintCustomLine[];
};

export const handleFiscalNonFiscalTerminalPrintDataForTransaction = (terminalPrintData: IterminalPrintData, polishCharacters: boolean, paperWidth: 55 | 80) => {
  let customLines: any[] = [{ type: "empty_line" }];

  if (terminalPrintData.InvAddText) {
    customLines = [...customLines, { type: "text_line", text: stripPolishCharacters(terminalPrintData.InvAddText, polishCharacters), fontSize: 0, centerText: 1 }];
  }

  customLines = [
    ...customLines,
    { type: "text_line", text: stripPolishCharacters(terminalPrintData.Name, polishCharacters), fontSize: 0, centerText: 0 },
    { type: "text_line", text: stripPolishCharacters(terminalPrintData.Addr1, polishCharacters), fontSize: 0, centerText: 0 },
    { type: "text_line", text: stripPolishCharacters(terminalPrintData.Addr2, polishCharacters), fontSize: 0, centerText: 0 },
    { type: "empty_line" },
    { type: "dot_line" },
    { type: "empty_line" },
    handleFiscalNonFiscalTerminalPrintDataTwoInLine(["Nr transakcji:", terminalPrintData.InvNo], paperWidth),
    handleFiscalNonFiscalTerminalPrintDataTwoInLine([`TID: ${terminalPrintData.TID}`, `MID: ${terminalPrintData.MID}`], paperWidth),
    { type: "empty_line" },
    {
      type: "text_line",
      text: `${terminalPrintData.CardName}(${terminalPrintData.EntryPAN})(${terminalPrintData.EntryPin})${terminalPrintData.CtlsMark || ''}${terminalPrintData.BLIK || ''}`,
      fontSize: 0,
      centerText: 0
    },
    { type: "empty_line" }
  ];

  if (terminalPrintData.PAN) customLines = [...customLines, { type: "text_line", text: `PAN: ${terminalPrintData.PAN}`, fontSize: 0, centerText: 0 }];
  if (terminalPrintData.AID) customLines = [...customLines, { type: "text_line", text: `AID: ${terminalPrintData.AID}`, fontSize: 0, centerText: 0 }];
  if (terminalPrintData.RRN) customLines = [...customLines, { type: "text_line", text: `RRN: ${terminalPrintData.RRN}`, fontSize: 0, centerText: 0 }];
  if (terminalPrintData.AuthNo) customLines = [...customLines, { type: "text_line", text: `KOD AUT: ${terminalPrintData.AuthNo}`, fontSize: 0, centerText: 0 }];
  if (terminalPrintData.ReferenceCode) customLines = [...customLines, { type: "text_line", text: `REF CODE: ${terminalPrintData.ReferenceCode}`, fontSize: 0, centerText: 0 }];
  
  customLines = [
    ...customLines,
    { type: "empty_line" },
    { type: "text_line", text: stripPolishCharacters(`${terminalPrintData.TrTotalName || ""}:`, polishCharacters), fontSize: 1, centerText: 0 },
    { type: "text_line", text: terminalPrintData.TrTotalAmt, fontSize: 1, centerText: 1 },
    { type: "dot_line" }
  ];

  if (terminalPrintData.TrResText) customLines = [...customLines, { type: "text_line", text: stripPolishCharacters(terminalPrintData.TrResText, polishCharacters), fontSize: 0, centerText: 1 }];
  if (terminalPrintData.TrResText2) customLines = [...customLines, { type: "text_line", text: stripPolishCharacters(terminalPrintData.TrResText2, polishCharacters), fontSize: 0, centerText: 1 }];
  if (terminalPrintData.HostResText) customLines = [...customLines, { type: "text_line", text: stripPolishCharacters(terminalPrintData.HostResText, polishCharacters), fontSize: 0, centerText: 1 }];


  customLines = [...customLines,{ type: "dot_line" }];

  if (terminalPrintData.ThxKeepPrintout) customLines = [...customLines, 
    { type: "text_line", text: stripPolishCharacters(terminalPrintData.ThxKeepPrintout, polishCharacters), fontSize: 0, centerText: 1 },
  ];

  if (terminalPrintData.TrDate) customLines = [...customLines, 
    { type: "text_line", text: stripPolishCharacters(terminalPrintData.TrDate, polishCharacters), fontSize: 0, centerText: 1 },
    { type: "empty_line" },
  ];

  return customLines as IfiscalNonFiscalPrintCustomLine[];
};

export const handleFiscalNonFiscalTerminalPrintDataForUpdateResult = (terminalPrintData: IterminalPrintData, polishCharacters: boolean, paperWidth: 55 | 80) => {
  return [
    { type: "empty_line" },
    { type: "text_line", text: stripPolishCharacters(terminalPrintData.UpdResult || "", polishCharacters), fontSize: 0, centerText: 1 },
    { type: "text_line", text: stripPolishCharacters(terminalPrintData.UpdDetails || "", polishCharacters), fontSize: 0, centerText: 1 },
    { type: "empty_line" },
    { type: "dot_line" },
    { type: "empty_line" },
    handleFiscalNonFiscalTerminalPrintDataTwoInLine([`TID: ${terminalPrintData.TID}`, `MID: ${terminalPrintData.MID}`], paperWidth),
    { type: "empty_line" },
    handleFiscalNonFiscalTerminalPrintDataTwoInLine(["Wersja aplikacji:", terminalPrintData.AppVer || ""], paperWidth),
    { type: "dot_line" }
  ] as IfiscalNonFiscalPrintCustomLine[];
};

// data handlers
const handleFiscalNonFiscalTerminalPrintDataTwoInLine = (strings: string[], paperWidth: 55 | 80) => {
  let text = "";
  if (strings.length !== 2) return { type: "text_line", text: text, fontSize: 0, centerText: 0 };

  let firstPartLength = paperWidth === 55 ? 21 : 28;
  const [first, second] = strings;
  text = `${first}${" ".repeat(firstPartLength - first.length)}${second}`;
  return { type: "text_line", text: text, fontSize: 0, centerText: 0 };
};

const handleFiscalNonFiscalTerminalPrintDataThreeInLine = (strings: string[], polishCharacters: boolean, paperWidth: 55 | 80) => {
  let text = "";
  if (strings.length !== 3) return { type: "text_line", text: text, fontSize: 0, centerText: 0 };

  let firstPartLength = paperWidth === 55 ? 23 : 31;
  let secondPartLength = paperWidth === 55 ? 19 : 25;
  const [first, second, third] = strings;
  text = `${first}${" ".repeat(firstPartLength - first.length - second.length)}${second}${" ".repeat(secondPartLength - third.length)}${third}`;
  return { type: "text_line", text: stripPolishCharacters(text, polishCharacters), fontSize: 0, centerText: 0 };
};

const handleFiscalNonFiscalTerminalPrintDataSetlLines = (list: string[], polishCharacters: boolean, paperWidth: 55 | 80) => {
  return list
    .map((item) => {
      const firstSplit = item.split("~");
      if (firstSplit.length === 3) return handleFiscalNonFiscalTerminalPrintDataThreeInLine(firstSplit, polishCharacters, paperWidth);

      const secondSplit = item.split(/~{1,}/);
      if (secondSplit.length === 5) {
        return [
          handleFiscalNonFiscalTerminalPrintDataThreeInLine([secondSplit[0], secondSplit[1], secondSplit[2]], polishCharacters, paperWidth),
          handleFiscalNonFiscalTerminalPrintDataThreeInLine([secondSplit[3], "", secondSplit[4]], polishCharacters, paperWidth)
        ];
      }

      return limitStringToPaperWidth(stripPolishCharacters(item, polishCharacters), paperWidth);
    })
    .flat(Infinity);
};
